import React, { useState, useEffect } from "react";
import api from '../utils/axiosInterceptor';
import { Box, Container, } from '@mui/material';
import CarCard from "../components/home/CarCard";
import SpecialOffers from "../components/home/SpecialOfferCard";
import Header from "../components/Header";
import Footer from "../components/Footer";



const SpecialOffersPage = ({ isProfile = false }) => {
  const [cars, setCars] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState([]);
  const [specialOffers, setSpecialOffers] = useState([]);

  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState({

  });

  const [availableYears, setAvailableYears] = useState([]);
  const availablePriceRanges = [
    { label: 'Under 1,000,000', value: '0-1000000' },
    { label: '1,000,000 - 2,000,000', value: '1000000-2000000' },
    { label: 'Above 2,000,000', value: '2000000-10000000' },
  ];

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const [carsResponse, modelsResponse, yearsResponse, brandsResponse, specialOfferResponse] = await Promise.all([
        api.get('/cars/all'),
        api.get('/cars/models'),
        api.get('/cars/years'),
        api.get('/car-type'),
        api.get('/cars/special-offers'),

      ]);
      setCars(carsResponse.data.results);
      setModels(modelsResponse.data);
      setAvailableYears(yearsResponse.data);
      setBrands(brandsResponse.data.results);
      setSpecialOffers(specialOfferResponse.data.results);


    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleTypeFilter = async (event) => {

    setSelectedCarType(event);

    try {
      setLoading(true);
      const response = await api.get('/cars', { params: { "carType": event.id } });
      setCars(response.data.results);


    } catch (error) {
      console.error("Error applying filters:", error);
    } finally {
      setLoading(false);
    }
  }

  const applyFilters = async () => {


    try {
      setLoading(true);
      const response = await api.get('/cars', { params: filters });
      setCars(response.data.results);


    } catch (error) {
      console.error("Error applying filters:", error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <Box sx={{ padding: 0, margin: 0, backgroundColor: 'background.default', width: "100%" }}>
      {!isProfile ? <Header /> : null}
      <Container maxWidth={false} sx={{ padding: 0, margin: 0, width: "100%" }}>
        <SpecialOffers isHome={false} cars={specialOffers} loading={loading} />
        <CarCard cars={cars} loading={loading} />
      </Container>
      {!isProfile ? <Footer /> : null}


    </Box>
  );
};


export default SpecialOffersPage;

