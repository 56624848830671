import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import api from '../utils/axiosInterceptor';
import { useSnackbar } from 'notistack';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import tokenManager from '../utils/tokenManager';

const FormField = styled('div')({
    marginBottom: '15px',
});

const Label = styled('div')({
    color: '#011C2F',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '5px',
});
const CarFormModal = ({ car, open, handleClose, initialValues, brands, carTypes, getCars }) => {
    const [thumbnail, setThumbnail] = useState(null);
    const [photos, setPhotos] = useState([]);
    const { enqueueSnackbar } = useSnackbar();


    const formik = useFormik({
        initialValues: {
            carType: { id: '', name: '' },
            brand: { id: '', name: '' },
            model: car?.model || '',
            year: car?.year || '',
            color: car?.color || '',
            fuelType: car?.fuelType || '',
            totalWeight: car?.totalWeight || '',
            singleWeight: car?.singleWeight || '',
            numberOfSeats: car?.numberOfSeats || '',
            allowedWorkBehaviour: car?.allowedWorkBehaviour || '',
            mileage: car?.mileage || '',
            price: car?.price || '',
            condition: car?.condition || '',
            location: car?.location || '',
            transmissionType: car?.transmissionType || '',
            additionalFeatures: car?.additionalFeatures || '',
            specialOffer: {
                title: car?.specialOffer?.title || '',
                description: car?.specialOffer?.description || '',
            }
        },
        validationSchema: Yup.object({
            carType: Yup.string().required('Required'),
            brand: Yup.string().required('Required'),
            model: Yup.string().required('Required'),
            year: Yup.number().required('Required').min(1900).max(new Date().getFullYear()),

            color: Yup.string().required('Required'),
            fuelType: Yup.string().required('Required'),
            totalWeight: Yup.number(),
            singleWeight: Yup.number(),
            numberOfSeats: Yup.number(),
            allowedWorkBehaviour: Yup.string(),

            mileage: Yup.number(),
            price: Yup.number().required('Required'),
            condition: Yup.string().required('Required'),
            location: Yup.string().required('Required'),
            transmissionType: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            handleFormSubmit({ ...values, thumbnail, photos });

            handleClose();
        },
    });

    const handleFileChange = (event, type) => {
        if (type === 'thumbnail') {
            setThumbnail(event.target.files[0]);
        } else {
            setPhotos([...photos, ...event.target.files]);
        }
    };


    // Update form values when editing an existing car
    useEffect(() => {
        if (car) {
            const updatedValues = {
                ...car,
                carType: car.carType?.id || '', // Set only the id
                brand: car.brand?.id || '' // Set only the id
            };


            formik.setValues(updatedValues);

        }
    }, [car]);


    const handleFormSubmit = async () => {
        const formValues = formik.values;

        console.log('Handling form submission:', formValues); // Debugging log
        try {
            const formData = new FormData();
            Object.keys(formValues).forEach((key) => {
                if (key === 'axelNumber') {
                    formData.append(key, JSON.stringify(formValues[key]));
                } if (key === 'isApproved') {
                    formData.append(key, formValues[key] || false);

                } else if (key === 'allowedWorkBehaviour') {
                    if (formValues[key] != '') {
                        formData.append(key, formValues[key] || []);

                    }
                }
                else if (key === 'additionalFeatures') {
                    formData.append(key, JSON.stringify(formValues[key] || []));
                } else if (key === 'checkedBy') {
                    formData.append(key, JSON.stringify(formValues[key] || {}));
                } else if (key === 'userId') {
                    formData.append(key, formValues[key].id || tokenManager.getUserId());
                } else if (key === 'brand') {
                    console.log('Brand:', formValues[key]);
                    if (initialValues?.id) {
                        formData.append(key, formValues[key].id || '');
                    } else {
                        formData.append(key, formValues[key] || '');
                    }
                } else if (key === 'carType') {
                    if (initialValues?.id) {
                        formData.append(key, formValues[key].id || '');
                    } else {
                        formData.append(key, formValues[key] || '');
                    }
                } else if (key === 'specialOffer') {
                  
                    formData.append(key, JSON.stringify(formValues[key] || {}));
                   
                } 
                else if (['mileage', 'totalWeight', 'singleWeight', 'motorCapacityCC', 'numberOfCylinders', 'numberOfSeats', 'price', 'year'].includes(key)) {
                    formData.append(key, formValues[key] ? Number(formValues[key]) : 0);
                } else if (key === 'approvedBy') {
                    if (formValues[key]) {
                        formData.append(key, formValues[key].id);
                    }
                } else if (!['createdAt', 'id'].includes(key)) {
                    formData.append(key, formValues[key] || '');
                }
            });
            if (thumbnail) {
                formData.append('thumbnail', thumbnail);
            } else {
                formData.delete('thumbnail');
            }
            if (photos.length > 0) {
                photos.forEach((photo) => {
                    formData.append('photos', photo);
                });
            } else {
                formData.delete('photos');
            }

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };

            if (formValues?.id) {

                try {
                    await api.patch(`/cars/${formValues.id}`, formData, config);

                    enqueueSnackbar('Car updated successfully', { variant: 'success' });

                } catch (error) {
                    enqueueSnackbar('Failed to save car: ' + (error.response?.data?.message || error.message), { variant: 'error' });

                }


            } else {
                formData.append('userId', tokenManager.getUserId());
                try {
                    await api.post('/cars', formData, config);
                    enqueueSnackbar('Car created successfully', { variant: 'success' });

                } catch (error) {
                    enqueueSnackbar('Failed to save car: ' + (error.response?.data?.message || error.message), { variant: 'error' });

                }
            }
            formik.resetForm();
            getCars();

            handleClose();
        } catch (error) {
            console.error('Error submitting form:', error.response?.data || error);
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>{car ? 'EDIT CAR' : 'ADD CAR'}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <FormField>
                        <Label>
                            CAR TYPE
                        </Label>
                        <TextField
                            fullWidth
                            select

                            name="carType"
                            value={formik.values && formik.values.carType || ''}
                            onChange={(event) => formik.setFieldValue('carType', event.target.value)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.carType && Boolean(formik.errors.carType)}
                            helperText={formik.touched.carType && formik.errors.carType}
                        >
                            {carTypes?.map((carType) => (
                                <MenuItem key={carType.id} value={carType.id}>
                                    {carType.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </FormField>
                    <FormField>
                        <Label>
                            BRAND
                        </Label>
                        <TextField
                            fullWidth
                            select

                            name="brand"
                            value={formik.values && formik.values.brand || ''}
                            onChange={(event) => formik.setFieldValue('brand', event.target.value)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.brand && Boolean(formik.errors.brand)}
                            helperText={formik.touched.brand && formik.errors.brand}
                        >
                            {brands?.map((brand) => (
                                <MenuItem key={brand.id} value={brand.id}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormField>

                    {['model', 'year', 'color', 'fuelType', 'condition', 'location', 'transmissionType', 'plateNumber',].map((field) => (
                        <FormField>
                            <Label>
                                {field.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, c => c.toUpperCase())}


                            </Label>
                            <TextField
                                fullWidth
                                name={field}
                                value={formik.values[field] || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[field] && Boolean(formik.errors[field])}
                                helperText={formik.touched[field] && formik.errors[field]}
                            />
                        </FormField>
                    ))}

                    {['totalWeight', 'singleWeight', 'numberOfSeats', 'mileage', 'price'].map((field) => (
                        <FormField>
                            <Label>
                                {field.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, c => c.toUpperCase())}


                            </Label>
                            <TextField
                                fullWidth
                                type="number"
                                name={field}
                                value={formik.values[field] || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[field] && Boolean(formik.errors[field])}
                                helperText={formik.touched[field] && formik.errors[field]}
                            />
                        </FormField>
                    ))}
                    <FormField>
                        <Label>
                            Additional Features
                        </Label>
                        <TextField
                            multiline
                            fullWidth
                            name="additionalFeatures"
                            value={formik.values['additionalFeatures'] || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['additionalFeatures'] && Boolean(formik.errors['additionalFeatures'])}
                            helperText={formik.touched['additionalFeatures'] && formik.errors["additionalFeatures"]}
                        />
                    </FormField>
                    <FormField>
                        <Label>
                            Special Offer Title
                        </Label>
                        <TextField
                            fullWidth
                            name="specialOffer.title"
                            value={formik.values.specialOffer?.title || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.specialOffer?.title && Boolean(formik.errors.specialOffer?.title)}
                            helperText={'(Discount, Loan, Cash)'||formik.touched.specialOffer?.title && formik.errors.specialOffer?.title}
                        />
                    </FormField>
                    <FormField>
                        <Label>
                        Special Offer Description
                        </Label>
                        <TextField
                            multiline
                            fullWidth
                            name="specialOffer.description"
                            value={formik.values.specialOffer?.description || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.specialOffer?.description && Boolean(formik.errors.specialOffer?.description)}
                            helperText={formik.touched.specialOffer?.description && formik.errors.specialOffer?.description}
                        />
                    </FormField>
                    <FormField>
                        <Label>

                            THUMBNAIL
                        </Label>

                        <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'thumbnail')} />
                    </FormField>  <FormField>
                        <Label>
                            PHOTOS

                        </Label>
                        <input type="file" accept="image/*" multiple onChange={(e) => handleFileChange(e, 'photos')} /></FormField>

                </DialogContent>
                <DialogActions>

                    <Button onClick={() => {
                        formik.resetForm();
                        handleClose()
                    }} color="secondary">Cancel</Button>
                    <Button onClick={handleFormSubmit} color="primary" variant="contained">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CarFormModal;
