import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ArrowBack } from "@mui/icons-material";
import SchedulingHeader from "../components/SchedulingHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { DialogActions } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";


import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import api from "../utils/axiosInterceptor";
import tokenManager from "../utils/tokenManager";

import {
  Grid,
  IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
  Person as PersonIcon,
  ConfirmationNumber as ConfirmationNumberIcon
} from '@mui/icons-material';


const theme = {
  primary: "#011c2f",
  secondary: "#149b09",
  accent: "#EFB600",
  background: "#f5f5f5",
  text: "#454a53",
  white: "#ffffff",
};


const BookingDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backgroundColor: '#011c2f',
    color: 'white',
    borderRadius: '12px',
    maxWidth: '600px',
    padding: '20px',
    width: '100%'
  }
});

const StatusBadge = styled('span')(({ status }) => ({
  padding: '4px 8px',
  borderRadius: '6px',
  fontSize: '12px',
  fontWeight: 'bold',
  backgroundColor:
    status === 'confirmed' ? '#4caf50' :
      status === 'pending' ? '#ffb300' :
        '#d32f2f',
  color: 'white',
  textTransform: 'capitalize',
}));


const PageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  padding: "20px",
  gap: "20px",
  backgroundColor: theme.palette.background.default,
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const LeftSideBox = styled(Box)(({ theme }) => ({
  width: '45%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '100%', // Add this line
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const DetailsBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  flex: 1, // Add this line
}));

const AdvertisementBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#011c2f",
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
}));



const RightSideBox = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '20px',
  padding: '20px',
  paddingTop: '0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%', // Add this line
}));

const CalendarContainer = styled(Box)({
  width: '100%',
  backgroundColor: theme.white,
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
});

const MonthGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '20px',
});

const MonthBox = styled(Box)({
  backgroundColor: theme.white,
  borderRadius: '10px',
  padding: '10px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
});

const CalendarGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gap: '2px',
});




const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backgroundColor: '#011c2f',
    color: 'white',
    borderRadius: '12px',
    maxWidth: '500px',
    width: '100%'
  }
});

const CustomButton = styled('button')({
  padding: '12px 24px',
  border: 'none',
  borderRadius: '6px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: '500',
  transition: 'all 0.2s ease',
});

const PrimaryButton = styled(CustomButton)({
  backgroundColor: '#efb600',
  color: 'white',
  '&:hover': {
    backgroundColor: '#d9a400',
  },
  '&:disabled': {
    backgroundColor: '#565656',
    cursor: 'not-allowed',
  }
});

const SecondaryButton = styled(CustomButton)({
  backgroundColor: 'transparent',
  color: '#fff',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
});


const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

function Scheduling() {
  const location = useLocation();
  const { carId } = location.state || {};

  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [carDetails, setCarDetails] = useState(null);


  const [userBookings, setUserBookings] = useState([]);

  const [monthsWithBookings, setMonthsWithBookings] = useState([]);
  const [hasActiveBooking, setHasActiveBooking] = useState(false);

  const [refreshTrigger, setRefreshTrigger] = useState(0);


  // Helper functions
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const CalendarCell = styled(Box)(({
    isHeader = false,
    isCurrentMonth = true
  }) => ({
    aspectRatio: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isHeader ? 'transparent' : 'white',
    borderRadius: '5px',
    fontSize: isHeader ? '0.7rem' : '0.8rem',
    fontWeight: isHeader ? 'bold' : 'normal',
    color: isHeader
      ? theme.text
      : isCurrentMonth ? theme.text : '#aaa',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      backgroundColor: isHeader ? 'transparent' : theme.background,
    }
  }));




  // Modify the fetchUserBookings function to check for active bookings
  const fetchUserBookings = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/booking?carId=${carId}`);
      const bookings = response.data.results;
      setUserBookings(bookings);

      // Check if user has any active (pending or confirmed) bookings
      const activeBooking = bookings.find(booking =>
        ['pending', 'confirmed'].includes(booking.status)
      );
      setHasActiveBooking(Boolean(activeBooking));

      return bookings;
    } catch (err) {
      setError('Failed to fetch user bookings');
      return [];
    } finally {
      setLoading(false);
    }
  };


  // Helper function to check if a date has a booking
  const getBookingForDate = (date) => {
    return userBookings.filter(booking => {
      const bookingDate = new Date(booking.date);
      return bookingDate.getDate() === date.getDate() &&
        bookingDate.getMonth() === date.getMonth() &&
        bookingDate.getFullYear() === date.getFullYear();
    });
  };

  const handleBookingCreated = async (booking) => {
    logEvent(analytics, "booking_created", {
      bookingId: booking.id,
      status: booking.status,
      date: booking.date,
    });
    // Refresh bookings
    await fetchUserBookings();

    // Update months with bookings
    const bookings = await fetchUserBookings();
    const activeMonths = getMonthsWithBookings(bookings);
    setMonthsWithBookings(activeMonths);

    // Trigger a refresh of the calendar view
    setRefreshTrigger(prev => prev + 1);
  };


  useEffect(() => {
    if (carId) {
      fetchCarDetails();
      fetchUserBookings().then(bookings => {
        const activeMonths = getMonthsWithBookings(bookings);
        setMonthsWithBookings(activeMonths);
      });
    }
  }, [carId, refreshTrigger]); // Add refreshTrigger as dependency


  const getMonthsWithBookings = (bookings) => {
    const months = new Set();
    const currentMonth = new Date().getMonth();

    // Always include current month
    months.add(currentMonth);

    // Add months with bookings
    bookings.forEach(booking => {
      const bookingDate = new Date(booking.date);
      months.add(bookingDate.getMonth());
    });

    return Array.from(months).sort((a, b) => a - b);
  };

  // Helper function to get color based on booking status
  const getStatusColor = (status) => {
    switch (status) {
      case 'confirmed':
        return '#4CAF50'; // Green
      case 'pending':
        return '#FFC107'; // Yellow
      case 'cancelled':
        return '#F44336'; // Red
      case 'completed':
        return '#2196F3'; // Blue
      default:
        return theme.text;
    }
  };

  const generateMonthDays = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const days = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(
        <CalendarCell
          key={`empty-${i}`}
          isCurrentMonth={false}
        />
      );
    }
    const hasActiveBooking = (bookings) =>
      bookings?.some(b => ['pending', 'confirmed'].includes(b.status));

    const hasBooking = (bookings) => bookings?.length > 0;

    // Add cells for each day of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(year, month, i);
      currentDate.setHours(0, 0, 0, 0);

      const booking = getBookingForDate(currentDate);
      const isToday = currentDate.getTime() === today.getTime();

      days.push(
        <CalendarCell
          key={i}
          isCurrentMonth={true}
          onClick={() => {
            const bookings = getBookingForDate(currentDate);
            if (bookings.length > 0) {
              // Show the most recent or active booking
              const activeBooking = bookings.find(b => ['pending', 'confirmed'].includes(b.status))
                || bookings[0];
              handleBookingClick(activeBooking);
            }
          }}
          sx={{
            cursor: booking ? 'pointer' : 'default',
            backgroundColor: hasActiveBooking(booking)
              ? getStatusColor('pending')
              : hasBooking(booking)
                ? getStatusColor(booking[0].status)
                : isToday
                  ? theme.primary
                  : 'white',
            color: (hasActiveBooking(booking) || hasBooking(booking) || isToday) ? 'white' : theme.text,

            position: 'relative',
            ...(booking && {
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '2px',
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                backgroundColor: 'white',
              }
            }),
            '&:hover': {
              backgroundColor: booking
                ? getStatusColor(booking.status)
                : isToday
                  ? theme.primary
                  : 'white',
              opacity: booking ? 0.8 : 1,
            }
          }}
        >
          {i}
          {booking.length > 1 && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 2,
                right: 2,
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: 'rgba(255,255,255,0.5)',
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {booking.length}
            </Box>
          )}
        </CalendarCell>
      );
    }

    // Complete the grid if needed
    const totalCells = days.length;
    const cellsNeeded = Math.ceil(totalCells / 7) * 7;
    for (let i = totalCells; i < cellsNeeded; i++) {
      days.push(
        <CalendarCell
          key={`empty-end-${i}`}
          isCurrentMonth={false}
        />
      );
    }

    return days;
  };

  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleBookingClick = (booking) => {
    logEvent(analytics, "booking_click", {
      bookingId: booking.id,
      status: booking.status,
      date: booking.date,
    });
    setSelectedBooking(booking);
  };








  const fetchCarDetails = async () => {
    try {
      const response = await api.get(`/cars/${carId}`);
      setCarDetails(response.data);
    } catch (err) {
      setError('Failed to fetch car details');
    }
  };

  const handleYearChange = (newYear) => {
    setYear(newYear);
  };

  const handleMenuToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchAvailableSlots = async (date) => {
    try {
      setLoading(true);
      const response = await api.get(`/booking/car/${carId}`, {
        params: {
          date: {
            gte: new Date(date).setHours(0, 0, 0),
            lte: new Date(date).setHours(23, 59, 59)
          }
        }
      });

      // Generate time slots excluding booked times
      const bookedTimes = response.data.results.map(booking =>
        new Date(booking.date).getHours()
      );

      const availableTimes = [];
      for (let i = 9; i <= 17; i++) { // 9 AM to 5 PM
        if (!bookedTimes.includes(i)) {
          availableTimes.push({
            time: `${i}:00`,
            hours: i,
            minutes: 0
          });
        }
      }

      setAvailableSlots(availableTimes);
    } catch (err) {
      setError('Failed to fetch available slots');
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelection = async (date) => {
    // Prevent selecting past dates
    if (date < new Date().setHours(0, 0, 0, 0)) {
      setError('Cannot select past dates');
      return;
    }

    setSelectedDate(date);
    setSelectedTime(null);
    await fetchAvailableSlots(date);
  };


  // Modify the handleBooking function to check for active bookings
  const handleBooking = async () => {
    if (hasActiveBooking) {
      setError('Please cancel your existing booking before making a new one');
      return;
    }

    if (!selectedDate || !selectedTime) {
      setError('Please select both date and time');
      return;
    }

    try {
      setLoading(true);
      const bookingDate = new Date(selectedDate);
      bookingDate.setHours(selectedTime.hours, selectedTime.minutes);

      const bookingData = {
        date: bookingDate,
        carId: carId,
        garageId: carDetails.garageId,
        status: 'pending'
      };
      console.log(bookingData)
      await api.post('/booking', bookingData);
      setSuccess('Booking created successfully');
      setSelectedDate(null);
      setSelectedTime(null);
      setConfirmDialogOpen(false);
      fetchUserBookings(); // Refresh bookings after creating new one
    } catch (err) {
      console.log(err)
      setError(err.response?.data?.message || 'Failed to create booking');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelBooking = async (bookingId) => {
    try {
      setLoading(true);
      await api.post(`/booking/${bookingId}/cancel`);
      await fetchUserBookings(); // Refresh the bookings
      setSelectedBooking(null);
      setSuccess('Booking cancelled successfully');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to cancel booking');
    } finally {
      setLoading(false);
    }
  };





  const BookingDialog = () => (
    <CustomDialog
      open={Boolean(selectedBooking)}
      onClose={() => setSelectedBooking(null)}
      maxWidth="sm"
      fullWidth
    >
      <Box
        sx={{
          background: 'linear-gradient(145deg, #011c2f 0%, #03294a 100%)',
          p: 4,
          borderRadius: '16px',
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            pb: 2,
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
          }}
        >
          <Typography
            sx={{
              fontSize: '28px',
              fontWeight: '700',
              color: 'white',
              letterSpacing: '-0.5px'
            }}
          >
            Booking Details
          </Typography>
          <IconButton
            onClick={() => setSelectedBooking(null)}
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {selectedBooking && (
          <>
            {/* Status Badge */}
            <Box sx={{ mb: 4 }}>
              <StatusBadge
                status={selectedBooking.status}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                  py: 1,
                  px: 2,
                  borderRadius: '30px',
                  fontSize: '14px',
                  letterSpacing: '0.5px'
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: 'currentColor'
                  }}
                />
                {selectedBooking.status.toUpperCase()}
              </StatusBadge>
            </Box>

            {/* Details Grid */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6}>
                <DetailItem
                  icon={<CalendarTodayIcon />}
                  label="Date"
                  value={new Date(selectedBooking.date).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DetailItem
                  icon={<AccessTimeIcon />}
                  label="Time"
                  value={new Date(selectedBooking.date).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DetailItem
                  icon={<PersonIcon />}
                  label="Customer"
                  value={selectedBooking.userId.fullName}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <DetailItem 
                  icon={<ConfirmationNumberIcon />}
                  label="Booking ID"
                  value={selectedBooking.id}
                />
              </Grid> */}
            </Grid>

            {/* Action Buttons */}
            {['pending', 'confirmed'].includes(selectedBooking.status) && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 4,
                  pt: 3,
                  borderTop: '1px solid rgba(255, 255, 255, 0.1)'
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setSelectedBooking(null)}
                  sx={{
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.2)',
                    }
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  loading={loading}
                  onClick={() => handleCancelBooking(selectedBooking.id)}
                  sx={{
                    bgcolor: '#ef5350',
                    '&:hover': {
                      bgcolor: '#d32f2f',
                    }
                  }}
                >
                  Cancel Booking
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </CustomDialog>
  );

  // Helper component for detail items
  const DetailItem = ({ icon, label, value }) => (
    <Box sx={{ color: 'white' }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        mb: 1,
        color: 'rgba(255, 255, 255, 0.7)'
      }}>
        {icon}
        <Typography variant="body2">
          {label}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          pl: 4
        }}
      >
        {value}
      </Typography>
    </Box>
  );


  return (
    <PageContainer>
      <Header />
      <MainContent>
        <LeftSideBox>
          <AdvertisementBox>
            <Box
              sx={{
                width: '100px',
                height: '100px',
                borderRadius: '10px',
                backgroundImage: 'url(./ads.svg)',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <Box>
              <Typography variant="h6" color="white">My Schedule History</Typography>
              <Typography variant="body2" color="white">
                View all your past and upcoming car viewing appointments
              </Typography>
            </Box>
          </AdvertisementBox>

          <DetailsBox>
            <Box display="flex" alignItems="center" gap={2}>
              <ArrowBack sx={{ transform: "scale(1.5)" }} />
              <Typography variant="h6" sx={{
                bgcolor: theme.primary,
                color: 'white',
                borderRadius: '10px',
                padding: '20px',
                flexGrow: 1,
                textAlign: 'center'
              }}>
                Viewing Schedule
              </Typography>
            </Box>

            {/* Legend for booking status colors */}
            <Box>
              <Typography variant="h6" mb={2}>Status Legend</Typography>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2
              }}>
                {['confirmed', 'pending', 'cancelled', 'completed'].map(status => (
                  <Box key={status} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '8px',
                    borderRadius: '4px',
                    backgroundColor: 'rgba(255,255,255,0.1)'
                  }}>
                    <Box sx={{
                      width: 16,
                      height: 16,
                      borderRadius: '50%',
                      backgroundColor: getStatusColor(status)
                    }} />
                    <Typography
                      variant="body2"
                      sx={{
                        textTransform: 'capitalize'
                      }}
                    >
                      {status}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {/* Selected Booking Preview */}
            {selectedBooking && (
              <Box sx={{
                mt: 3,
                p: 2,
                borderRadius: '10px',
                backgroundColor: 'rgba(255,255,255,0.1)'
              }}>
                <Typography variant="h6" mb={2}>Selected Booking Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="body2">
                    Date: {new Date(selectedBooking.date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">
                    Time: {new Date(selectedBooking.date).toLocaleTimeString()}
                  </Typography>
                  <Typography variant="body2" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    Status:
                    <Box component="span" sx={{
                      color: getStatusColor(selectedBooking.status),
                      textTransform: 'capitalize'
                    }}>
                      {selectedBooking.status}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
          </DetailsBox>
        </LeftSideBox>

        <RightSideBox>
          <CalendarContainer>
            <SchedulingHeader
              year={year}
              onYearChange={handleYearChange}
              onMenuToggle={handleMenuToggle}
              onBookingCreated={handleBookingCreated}
              carId={carId}
              fetchUserBookings={fetchUserBookings}
              hasActiveBooking={hasActiveBooking}
              refreshCalendar={() => setRefreshTrigger(prev => prev + 1)}
            />
            <MonthGrid>
              {monthsWithBookings.map((monthIndex) => (
                <MonthBox key={monthIndex}>
                  <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                    {monthIndex + 1} {/* Display month number instead of name */}
                  </Typography>
                  <CalendarGrid>
                    {weekDays.map((day) => (
                      <CalendarCell key={day} isHeader>
                        {day}
                      </CalendarCell>
                    ))}
                    {generateMonthDays(year, monthIndex)}
                  </CalendarGrid>
                </MonthBox>
              ))}
            </MonthGrid>
          </CalendarContainer>
        </RightSideBox>
      </MainContent>
      <BookingDialog />


      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(success)}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSuccess(null)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {success}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Footer />
    </PageContainer>
  );
}


const TimeSlotSelector = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '10px',
  margin: '20px 0'
});

const TimeSlot = styled(Box)(({ selected }) => ({
  padding: '10px',
  borderRadius: '5px',
  backgroundColor: selected ? theme.accent : theme.white,
  color: selected ? theme.white : theme.text,
  cursor: 'pointer',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: selected ? theme.accent : theme.background,
  }
}));
export default Scheduling;