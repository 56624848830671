import React from 'react';
import { DirectionsCar } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Option 1: Using the existing DirectionsCar with rotation
const CarIconRight = styled(DirectionsCar)`
  position: absolute;
  bottom: 2px;
  color: #EFB600;
  font-size: 20px;
  transform: scaleX(-1); // This flips the car horizontally
  animation: ${props => props.driveAnimation} 2s linear infinite;
`;

// Option 2: Creating a custom right-facing car icon
const RightFacingCar = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="currentColor"
  >
    <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 7h10.29l1.08 3.11H5.77L6.85 7zM19 17H5v-5h14v5z"/>
    <circle cx="7.5" cy="14.5" r="1.5"/>
    <circle cx="16.5" cy="14.5" r="1.5"/>
  </svg>
);

// const CarIconCustom = styled(RightFacingCar)`
//   position: absolute;
//   bottom: 2px;
//   color: #EFB600;
//   font-size: 20px;
//   animation: ${props => props.driveAnimation} 2s linear infinite;
// `;

const drive = keyframes`
  0% {
    left: -40px;
  }
  100% {
    left: calc(100% + 40px);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 24px;
  overflow: hidden;
`;

const Road = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const LoadingText = styled.span`
  color: white;
  animation: ${bounce} 1s ease-in-out infinite;
  margin-left: 8px;
`;

// You can choose which version to use by changing which CarIcon component is rendered
const ButtonCarLoader = ({ text = "Loading..." }) => {
  return (
    <AnimationWrapper>
      <Road />
      {/* Option 1: Using flipped DirectionsCar */}
      <CarIconRight driveAnimation={drive} />
      
      {/* Option 2: Using custom right-facing car */}
      {/* <CarIconCustom driveAnimation={drive} /> */}
      
      <LoadingText>{text}</LoadingText>
    </AnimationWrapper>
  );
};

export default ButtonCarLoader;