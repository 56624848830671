
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Box } from '@mui/material';
const images = [
    require('../home/car6.jpg'),
    require('../home/car7.jpg'),
    require('../home/car5.jpg'),
    require('../home/car4.jpg'),
    require('../home/car3.jpg'),
    require('../home/car2.jpg'),


];

function ImageCarousel() {
  return (
    <Box sx={{  flexGrow: 1, margin: 'auto',}} >
      <Carousel animation="slide" interval={6000}>
        {images.map((image, i) => (
          <Paper key={i} elevation={0}>
            <Box
              component="img"
              sx={{
                width: '100%',
                height: {
                    xs: '200px', // Set height for mobile devices (xs)
                    md: '500px', 
                    lg:'500px'// Set height for medium devices (md) and up
                  },
                objectFit: 'cover'
              }}
              src={image}
              alt={`Slide ${i}`}
            />
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
}

export default ImageCarousel;