import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import LoadingAnimation from '../LoadingAnimation';
import { Box, Typography } from '@mui/material';
import { Contour1 } from '../carListing/Contour1';
import FavoriteButton from './FavoriteButton';
import tokenManager from '../../utils/tokenManager';


// Updated styled components with enhanced aesthetics
const StyledFrame = styled.div`
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  padding: 30px 20px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 30px 40px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #2563eb, #3b82f6);
    border-radius: 2px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeaderContent = styled.div`
  flex: 1;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 40px;
  }
`;

const MainTitle = styled.h1`
  color: #1a1a1a;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: -0.5px;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const MainDescription = styled.p`
  color: #4b5563;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 16px;
  line-height: 1.6;
  max-width: 100%;

  @media (min-width: 768px) {
    font-size: 18px;
    max-width: 70%;
  }
`;

const ExploreMore = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 12px 24px;
  background: #ffffff;
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-decoration: none;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ExploreMoreText = styled.span`
  color: #2563eb;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-weight: 600;
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const CarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  
  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CarContainer = styled(Link)`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  aspect-ratio: 1.1;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }
`;

const CarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;

  ${CarContainer}:hover & {
    transform: scale(1.05);
  }
`;
const GradinetBackground = styled.div`
 position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height:250px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 80%);

`;

const CarTitle = styled.h2`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px 20px;
  color: white;
  font-family: "Inter-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  transform: translateY(0);
  transition: all 0.3s ease;
  
  @media (min-width: 768px) {
    font-size: 22px;
  }

  ${CarContainer}:hover & {
    padding-bottom: 40px;
  }
`;


// Add these new components for additional features
const PriceTag = styled.div`
  position: absolute;
  bottom: 0px;
  right: 20px;
  background: rgba(255, 255, 255, 0.95);
  padding: 8px 14px;
  border-radius: 50px;
  color: #2563eb;
  font-weight: 600;
  font-size: 18px;
  
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

// const Badge = styled.div`
//   position: absolute;
//   top: 20px;
//   left: 20px;
//   background: #2563eb;
// color: white;
// padding: 6px 12px;
// border-radius: 50px;
// font-size: 14px;
// font-weight: 600;
//   text-transform: uppercase;
// `;

const theme = {
  primary: "#011c2f",
  secondary: "#149b09",
  accent: "#EFB600",
  background: "#f5f5f5",
  text: "#454a53",
  white: "#ffffff",
};

const Frame29 = styled(Box)(({ isApproved }) => ({
  alignItems: "center",
  backgroundColor: isApproved ? theme.secondary : theme.accent,
  color: "white",
  padding: "6px 12px",
  borderRadius: "50px",
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "uppercase",
  justifyContent: "center",
  position: "absolute",
  top: "20px",
  left: "20px",
  color: theme.white,
  fontFamily: "'Poppins', sans-serif",
  textAlign: "center",

}));
// Update the component to include new features
const CarCard = ({ cars, loading, isHome }) => {
  return (
    <StyledFrame>
      <HeaderSection>
        <HeaderContent>
          <MainTitle>Discover Your Perfect Ride</MainTitle>
          <MainDescription>
            Explore our handpicked selection of premium vehicles. Each car is thoroughly inspected and ready for its new owner.
          </MainDescription>
        </HeaderContent>
        {isHome ? <ExploreMore to="/all-cars">
          <ExploreMoreText>View All Vehicles</ExploreMoreText>
          <ArrowForwardIcon style={{ color: '#2563eb', fontSize: '24px' }} />
        </ExploreMore> : null}
      </HeaderSection>

      {loading ? (
        <LoadingAnimation />
      ) : (
        <CarGrid>
          {cars.map((car) => (
            <CarContainer key={car._id} to={`/car-listing/${car.id}`}>
              <CarImage
                src={car.thumbnail || "/api/placeholder/400/360"}
                alt={car.model || 'Car'}
              />

              <Frame29 isApproved={car.isApproved}>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.white,
                    fontFamily: "'Poppins', sans-serif",
                    textAlign: "center",
                    fontSize: "8px",
                    fontWeight: "500"
                  }}
                >
                  {car.isApproved ? "Inspection Passed" : "Pending Inspection"}
                </Typography>
                <Contour1 className="contour-instance" />
              </Frame29>
              <GradinetBackground>
                <CarTitle>
                  {car.category || `${car.brand?.name} ${car.model}`}
                  <PriceTag>ETB {car.price?.toLocaleString() || '00,000'}
                  </PriceTag>
                </CarTitle>
              </GradinetBackground>
            </CarContainer>
          ))}
          <CarContainer to="/profile">
            <CarImage
              src="/logo.jpg"
              alt="More Cars"
            />
            <GradinetBackground>
              <CarTitle>List your Car
                <PriceTag>Add Car</PriceTag>
              </CarTitle>
            </GradinetBackground>
          </CarContainer>
        </CarGrid>
      )}
    </StyledFrame>
  );
};

export default CarCard;