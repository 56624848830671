import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Call } from "../components/carListing/Call";
import { Contour } from "../components/carListing/Contour";
import { Contour1 } from "../components/carListing/Contour1";
import { Contour2 } from "../components/carListing/Contour2";
import { Tiktok } from "../components/carListing/Tiktok";
import { Whatsapp } from "../components/carListing/Whatsapp";
import Footer from "../components/Footer";
import Header from "../components/Header";
import api from "../utils/axiosInterceptor";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";
import EnhancedFrame12 from "../components/carListing/EnhancedFrame12";




const theme = {
  primary: "#011c2f",
  secondary: "#149b09",
  accent: "#EFB600",
  background: "#f5f5f5",
  text: "#454a53",
  white: "#ffffff",
};

const CarDetails = styled(Box)(({ theme }) => ({
  backgroundColor: theme.background,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));



const Frame4 = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "15px",
  padding: "30px",
  width: "100%",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
    padding: "15px",
    gap: "20px",
  },
}));

const Frame5 = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "75%",
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
}));

const Frame6 = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "15px",
  width: "100%",
  [theme.breakpoints.down('sm')]: {
    flexDirection: "column",
    height:600
  },
}));



const Frame7 = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "450px",
  width: "100px",
  overflowY: "auto",
  [theme.breakpoints.down('sm')]: {
    flexDirection: "row",
    height: "auto",
    width: "100%",
    overflowX: "auto",
  },
}));

const Frame11 = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "30px",
  padding: "20px 0px 0px 0px",
  width: "100%",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
    gap: "20px",
  },
}));

const Frame12 = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
}));

const Frame18 = styled(Box)(({ theme }) => ({
  width: "45%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
}));



const Frame27 = styled(Box)(({ theme }) => ({
  width: "25%",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
}));







const FrameWrapper = styled(Box)({
  alignItems: "flex-start",
  alignSelf: "stretch",
  display: "flex",
  flex: "0 0 auto",
  flexDirection: "column",
  gap: "10px",
  position: "relative",
  width: "100%",
});


const DivWrapper = styled(Box)({
  alignItems: "flex-end",
  alignSelf: "stretch",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  justifyContent: "center",
  position: "relative",
  width: "100%",
});

const StyledTypography = styled(Typography)({
  alignSelf: "stretch",
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "32px",
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: "normal",
  marginTop: "-1px",
  position: "relative",
});

const Frame14 = styled(Box)({
  alignItems: "center",
  alignSelf: "stretch",
  backgroundColor: theme.white,
  borderRadius: "15px",
  display: "flex",
  justifyContent: "space-between",
  padding: "20px",
  position: "relative",
  width: "100%",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
});

const Frame15 = styled(Box)({
  alignItems: "flex-start",
  display: "inline-flex",
  flexDirection: "column",
  position: "relative",
});

const PriceTypography = styled(Typography)({
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "32px",
  fontWeight: 700,
  letterSpacing: 0,
  lineHeight: "normal",
  position: "relative",
  whiteSpace: "nowrap",
  width: "fit-content",
});

const StyledButton = styled(Button)({
  alignItems: "center",
  backgroundColor: theme.accent,
  borderRadius: "10px",
  boxShadow: "0px 3px 15px #00000026",
  display: "inline-flex",
  gap: "10px",
  justifyContent: "center",
  padding: "15px 20px",
  position: "relative",
  cursor: "pointer",
  color: theme.white,
  fontWeight: "bold",
  transition: "all 0.3s ease",
  '&:hover': {
    backgroundColor: "#d9a400",
    transform: "translateY(-2px)",
  },
});

const InspectionBadge = styled(Box)(({ isApproved }) => ({
  alignItems: "left",
  backgroundColor: isApproved ? theme.secondary : theme.accent,
  borderRadius: "10px",
  display: "inline-flex",
  gap: "5px",
  justifyContent: "center",
  padding: "15px 20px",
  position: "relative",
  width: "250px",
}));

const BadgeText = styled(Typography)({
  color: theme.white,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "normal",
});

const DescriptionTitle = styled(Typography)({
  alignSelf: "stretch",
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 500,
  position: "relative",
});

const DescriptionTypography = styled(Typography)({
  alignSelf: "stretch",
  color: theme.text,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 400,
  letterSpacing: "-0.15px",
  lineHeight: "30px",
  position: "relative",
});

const Frame19 = styled(Box)({
  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
  flex: "0 0 auto",
  gap: "20px",
  justifyContent: "space-around",
  position: "relative",
  width: "100%",
});


const Frame22 = styled(Box)({
  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
  flex: "0 0 auto",
  justifyContent: "space-between",
  position: "relative",
});

const Frame23 = styled(Box)({
  alignItems: "center",
  display: "inline-flex",
  flex: "0 0 auto",
  gap: "20px",
  position: "relative",
});

const Frame24 = styled(Box)(({ profilePicture }) => ({
  backgroundImage: `url(${profilePicture || "/listings/frame-1000005178.png"})`,
  backgroundPosition: "50% 50%",
  backgroundSize: "cover",
  borderRadius: "100px",
  height: "50px",
  position: "relative",
  width: "50px",
}));

const Frame25 = styled(Box)({
  alignItems: "flex-start",
  alignSelf: "stretch",
  display: "flex",
  flex: "0 0 auto",
  flexDirection: "column",
  gap: "30px",
  justifyContent: "center",
  position: "relative",
  width: "100%",
});



const Frame28 = styled(Box)(({ image }) => ({
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  backgroundImage: image ? `url(${image})` : "url('/listings/fallback-image.png')",
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  display: 'flex',
  gap: '10px',
  height: '200px',
  justifyContent: 'flex-end',
  padding: '10px',
  position: 'relative',
  width: '100%',
  minWidth: '0',
}));

const Frame29 = styled(Box)(({ isApproved }) => ({
  alignItems: "center",
  backgroundColor: isApproved ? theme.secondary : theme.accent,
  borderRadius: "5px",
  display: "inline-flex",
  flex: "0 0 auto",
  gap: "3px",
  justifyContent: "center",
  padding: "3px 5px",
  position: "absolute",
  top: "10px",
  right: "10px",
  color: theme.white,
  fontFamily: "'Poppins', sans-serif",
  textAlign: "center",
  fontSize: "8px",
  fontWeight: "500",
}));

const Frame30 = styled(Box)({
  alignItems: "flex-start",
  alignSelf: "stretch",
  display: "flex",
  flex: "0 0 auto",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
  position: "relative",
  width: "100%",
});


const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  padding: "40px 20px",
  backgroundColor: "#f8f9fa",
  [theme.breakpoints.down('md')]: {
    padding: "20px 10px",
  },
}));

const ProductImages = styled(Box)(({ theme }) => ({
  backgroundPosition: "50% 50%",
  backgroundSize: "cover",
  borderRadius: "20px",
  flex: 1,
  height: "490px",
  transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
  position: "relative",
  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
  '&:hover': {
    transform: "scale(1.02)",
  },
  '&::after': {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "20px",
    background: "linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,0.3))",
  },
  [theme.breakpoints.down('sm')]: {
    height: "300px",
    backgroundPosition: "center",

  },
}));

const Frame8 = styled(Box)({
  backgroundPosition: "50% 50%",
  backgroundSize: "cover",
  borderRadius: "10px",
  height: "100px",
  width: "80",
  cursor: "pointer",
  transition: "all 0.3s ease",
  flexShrink: 0,
  border: "3px solid transparent",
  '&:hover': {
    transform: "translateY(-3px)",
    boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
    border: `3px solid ${theme.accent}`,
  },
  '&.active': {
    border: `3px solid ${theme.accent}`,
    boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
  },
});

const ScheduleButton = styled(Button)({
  alignItems: "center",
  background: `linear-gradient(135deg, ${theme.accent} 0%, #ffd700 100%)`,
  borderRadius: "10px",
  boxShadow: "0px 4px 20px rgba(239, 182, 0, 0.3)",
  display: "flex",
  flex: 1,
  flexGrow: 1,
  gap: "10px",
  justifyContent: "center",
  padding: "20px",
  position: "relative",
  fontWeight: "600",
  fontSize: "24px",
  color: theme.white,
  transition: "all 0.3s ease",
  overflow: "hidden",
  '&::before': {
    content: '""',
    position: "absolute",
    top: 0,
    left: -100,
    width: "100%",
    height: "100%",
    background: "linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)",
    transition: "0.5s",
  },
  '&:hover': {
    transform: "translateY(-3px)",
    boxShadow: "0px 6px 25px rgba(239, 182, 0, 0.4)",
    '&::before': {
      left: 100,
    },
  },
});

const Frame26 = styled(Box)({
  alignItems: "center",
  alignSelf: "stretch",
  backgroundColor: theme.white,
  borderRadius: "10px",
  display: "flex",
  flex: "0 0 auto",
  gap: "20px",
  justifyContent: "center",
  padding: "15px 20px",
  position: "relative",
  cursor: "pointer",
  transition: "all 0.3s ease",
  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
  '&:hover': {
    backgroundColor: "#f8f9fa",
    transform: "translateX(5px)",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
});

const CarCard = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '454px',
  transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
  boxShadow: "0px 4px 15px rgba(0,0,0,0.08)",
  background: theme.white,
  '&:hover': {
    transform: "translateY(-8px)",
    boxShadow: "0px 8px 25px rgba(0,0,0,0.15)",
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const Frame21 = styled(Box)({
  alignItems: "center",
  alignSelf: "stretch",
  backgroundColor: theme.white,
  borderRadius: "15px",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  flexGrow: 1,
  gap: "20px",
  padding: "25px",
  position: "relative",
  boxShadow: "0px 4px 20px rgba(0,0,0,0.08)",
  transition: "all 0.3s ease",
  '&:hover': {
    boxShadow: "0px 6px 25px rgba(0,0,0,0.12)",
  },
});

// Add this new component for price animation
const AnimatedPrice = styled(Typography)({
  color: theme.accent,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "22px",
  fontWeight: 700,
  position: "relative",
  display: "inline-block",
  '&::after': {
    content: '""',
    position: "absolute",
    bottom: -5,
    left: 0,
    width: "100%",
    height: "2px",
    background: theme.accent,
    transform: "scaleX(0)",
    transition: "transform 0.3s ease",
  },
  '&:hover::after': {
    transform: "scaleX(1)",
  },
});


function CarListing() {

  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recentListings, setRecentListings] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(car?.photos[0] || '/placeholder-image.jpg');


  const [activeThumb, setActiveThumb] = useState(0);

  const handleThumbClick = (photo, index) => {
    setSelectedPhoto(photo);
    setActiveThumb(index);
  };


  const navigate = useNavigate()

  const handleCardClick = (carId) => {
    console.log("======handleCardClick======", carId)
    navigate(`/car-listing/${carId}`);
  };


  useEffect(() => {
    setLoading(true);
    setCar(null);
    setSelectedPhoto(null);
    fetchCarDetails();
    fetchRecentListings();
  }, [id]);  // Re-run the effect when the id changes

  const fetchCarDetails = async () => {
    try {
      const response = await api.get(`/cars/${id}`);
      setCar(response.data);
      setSelectedPhoto(response.data.photos[0]);
    } catch (error) {
      console.error("Error fetching car details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchCarDetails();
    fetchRecentListings();

  }, []);

  const handleScheduleClick = () => {
    navigate("/scheduling", { state: { carId: id } });

  };




  const fetchRecentListings = async () => {
    try {
      const response = await api.get('/cars', {
        params: {
          sortBy: 'createdAt:desc',
          limit: 6 // Adjust this number as needed
        }
      });
      setRecentListings(response.data.results);
    } catch (error) {
      console.error("Error fetching recent listings:", error);
    }
  };



  if (loading) {
    return <LoadingAnimation />;
  }

  if (!car) {
    return <div>Car not found</div>;
  }

  return (
    <CarDetails>
      <Header />

      <MainContent>
        <Frame4>
          <Frame5>
            <Frame6>
              <ProductImages style={{ backgroundImage: `url(${selectedPhoto})` }} />
              <Frame7>
                {car.photos.slice(1, 5).map((photo, index) => (
                  <Frame8
                    key={index}
                    className={activeThumb === index ? 'active' : ''}
                    style={{ backgroundImage: `url(${photo})` }}
                    onClick={() => handleThumbClick(photo, index)}
                  />
                ))}
              </Frame7>
            </Frame6>
            <FrameWrapper>
              <Frame11>
                <EnhancedFrame12 car={car} />
                <Frame18>
                  <Frame19>
                    <ScheduleButton onClick={handleScheduleClick}>Schedule Viewing</ScheduleButton>
                  </Frame19>
                  <Frame21>
                    <Frame22>
                      <Frame23>
                        <Frame24 profilePicture={car.userId?.profilePicture} />
                        <Typography variant="h6">{car.userId?.fullName || "Unknown Seller"}</Typography>
                      </Frame23>
                      <Typography variant="body1" sx={{ color: car.userId?.verified ? theme.secondary : theme.accent }}>
                        {car.userId?.verified ? "Verified Seller" : "Unverified Seller"}
                      </Typography>
                    </Frame22>
                    <Frame25>
                      <Frame26>
                        <Call className="icon-instance-node-3" />
                        <Typography
                          variant="body1"
                          style={{ cursor: car.userId?.phone ? "pointer" : "default", color: car.userId?.phone ? "black" : "black" }}
                          onClick={() => {
                            if (car.userId?.phone) {
                              window.location.href = `tel:${car.userId.phone}`;
                            } else {
                              alert("No phone number available.");
                            }
                          }}
                        >
                          {car.userId?.phone || "No phone available"}
                        </Typography>
                      </Frame26>

                      <Frame26>
                        <Tiktok className="icon-instance-node-3" />
                        <Typography variant="body1">Telegram Chat</Typography>
                      </Frame26>
                      <Frame26>
                        <Whatsapp className="icon-instance-node-3" />
                        <Typography variant="body1">WhatsApp Chat</Typography>
                      </Frame26>
                      <AnimatedPrice>
                        Special Offer
                      </AnimatedPrice>
                      <Typography variant="h5">{car.specialOffer?.title}</Typography>
                      <Typography variant="body1">{car.specialOffer?.description}</Typography>
                    </Frame25>
                  </Frame21>
                </Frame18>
              </Frame11>
            </FrameWrapper>
          </Frame5>
          <Frame27>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "32px",
                fontWeight: "600",
                lineHeight: "38.19px",
                textAlign: "left",
                alignSelf: "left",
                color: theme.primary,
              }}
            >
              Recent Listings
            </Typography>
            {recentListings.map((car) => {
              const imageUrl = car.thumbnail || car.photos[0];
              return (
                <CarCard
                  key={car.id}
                  to={`/car-listing/${car.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCardClick(car.id);
                  }}
                >
                  <Frame28 image={imageUrl}>
                    <Frame29 isApproved={car.isApproved}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme.white,
                          fontFamily: "'Poppins', sans-serif",
                          textAlign: "center",
                          fontSize: "8px",
                          fontWeight: "500"
                        }}
                      >
                        {car.isApproved ? "Inspection Passed" : "Pending Inspection"}
                      </Typography>
                      <Contour1 className="contour-instance" />
                    </Frame29>
                  </Frame28>
                  <Frame30>
                    <Typography
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "13px",
                        fontWeight: "600",
                        lineHeight: "19.5px",
                        textAlign: "left",
                        color: theme.primary
                      }}
                      variant="body1"
                    >
                      {`${car.brand?.name}: ${car.year} ${car.model}`}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        textAlign: "left",
                        color: theme.primary,
                      }}
                    >
                      <Contour2 className="icon-instance-node-4" />
                      <Typography variant="body2">Price</Typography>
                      {/* <Typography variant="body2">{`${car.price.toLocaleString()} ETB`}</Typography> */}
                      <AnimatedPrice>
                        {car.price.toLocaleString()} ETB
                      </AnimatedPrice>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2" sx={{ color: car.userId?.verified ? theme.secondary : theme.accent }}>
                        {car.userId?.verified ? "Verified Seller" : "Unverified Seller"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "16.71px",
                          textAlign: "left",
                          color: theme.primary,
                        }}
                      >
                        {car.userId?.fullName || "Seller"}
                      </Typography>
                    </Box>
                  </Frame30>
                </CarCard>
              );
            })}
          </Frame27>
        </Frame4>
      </MainContent>
      <Footer />
    </CarDetails>
  );
}

export default CarListing;