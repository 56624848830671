import authService from '../../services/authService';
import tokenManager from '../../utils/tokenManager';

// Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const TOKEN_REFRESH = 'TOKEN_REFRESH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

// Action Creators
export const login = (credentials, rememberMe) => async (dispatch) => {
  try {
    const response = await authService.login(credentials);
    
    // Store tokens and user data
    tokenManager.setTokens(response.data.tokens);
    tokenManager.setRememberMe(rememberMe);
    tokenManager.setUser( response.data.user);
    
    // Dispatch success with both user and tokens
    dispatch({ 
      type: LOGIN_SUCCESS, 
      payload: {
        user: response.data.user,
        tokens: response.data.tokens
      }
    });

    return response.data; // Return data for component usage if needed
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Login failed';
    dispatch({ 
      type: LOGIN_FAILURE, 
      payload: errorMessage 
    });
    throw error; // Re-throw for component error handling
  }
};

export const signup = (userData) => async (dispatch) => {
  try {
    const response = await authService.signup(userData);
    
    dispatch({ 
      type: SIGNUP_SUCCESS, 
      payload: {
        user: response.data.user,
        tokens: response.data.tokens
      }
    });
// Store tokens and user data
tokenManager.setTokens(response.data.tokens);
tokenManager.setUser( response.data.user);
console.log(response.data)
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Signup failed';
    dispatch({ 
      type: SIGNUP_FAILURE, 
      payload: errorMessage 
    });
    throw error;
  }
};

export const logout = () => async (dispatch) => {
  try {
    await authService.logout();
    tokenManager.clearTokens();
    dispatch({ type: LOGOUT });
  } catch (error) {
    console.error('Logout error:', error);
    // Still clear tokens and dispatch logout even if server call fails
    tokenManager.clearTokens();
    dispatch({ type: LOGOUT });
  }
};

export const refreshToken = () => async (dispatch) => {
  try {
    const response = await authService.refreshToken();
    tokenManager.setTokens({
      ...tokenManager.getTokens(),
      accessToken: response.data.accessToken
    });
    
    dispatch({
      type: TOKEN_REFRESH,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: 'Token refresh failed'
    });
    // If refresh fails, logout user
    dispatch(logout());
  }
};

export const clearAuthError = () => ({
  type: CLEAR_AUTH_ERROR
});

// Optional: Add action to check auth status on app load
export const checkAuthStatus = () => (dispatch) => {
  const tokens = tokenManager.getTokens();
  const user = tokenManager.getUser();
  
  if (tokens && user) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user,
        tokens
      }
    });
  }
};