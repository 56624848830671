import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Link, Box, Typography, Container, Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import LogoContent from './LogoContent';
import api from '../utils/axiosInterceptor';
import tokenManager from '../utils/tokenManager';
import { login, LOGIN_FAILURE, LOGIN_SUCCESS } from '../redux/actions/authActions';


const LoginSchema = Yup.object().shape({
  phone: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '8px',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px',
  padding: '10px 0',
  '&:hover': {
    backgroundColor: '#333',
  },
});

const StyledPhoneInput = styled(PhoneInput)({
  '& .react-tel-input .form-control': {
    width: '100%',
    height: '56px',
    borderRadius: '8px',
    fontSize: '16px',
  },
});

const Login = () => {
  const location = useLocation(); 
  const from = location.state?.from?.pathname || '/home';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // In Login.js
const handleSubmit = async (values, { setSubmitting }) => {
  try {
    await dispatch(login(
      {
        phone: values.phone,
        password: values.password
      }, 
      values.rememberMe
    ));

    setSnackbarSeverity('success');
    setSnackbarMessage('Login successful!');
    setOpenSnackbar(true);

    setTimeout(() => {
      navigate(from);
    }, 1500);
  } catch (error) {
    if (error.response?.status === 403 && 
        error.response.data.message.includes('Phone number not verified')) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Phone number not verified. Please verify your phone number.');
      navigate('/verify-otp', { state: { phone: values.phone } });
    } else {
      setSnackbarSeverity('error');
      setSnackbarMessage(error.response?.data?.message || 'Login failed. Please try again.');
    }
    setOpenSnackbar(true);
  } finally {
    setSubmitting(false);
  }
};

  return (
    <Box
      sx={{
        backgroundImage: 'url(/cover.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        padding: { xs: '20px', md: '0' }, // Responsive padding
      }}
    >
      <Container maxWidth="lg"> {/* Adjust maxWidth to lg for responsiveness */}
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            {/* <LogoContent /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '16px',
                padding: { xs: '20px', md: '40px' }, // Responsive padding
                backdropFilter: 'blur(10px)',
                maxWidth: { xs: '100%', md: '400px' }, // Responsive maxWidth
                margin: '0 auto', // Centering the box
              }}
            >
              <Typography variant="h4" component="h1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Log In
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Welcome back to Mekina Gebeya
              </Typography>
              <Formik
                initialValues={{ phone: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, setFieldValue }) => (
                  <Form>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Enter your phone number
                    </Typography>
                    <StyledPhoneInput
                      country={'et'}
                      value={phone}
                      
                      onChange={(phone) => {
                        setPhone(phone);
                        setFieldValue('phone', '+' + phone); // Ensure the phone number includes the country code
                      }}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                      }}
                    />
                    {touched.phone && errors.phone && (
                      <Typography color="error" variant="caption">
                        {errors.phone}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                      Enter Password
                    </Typography>
                    <Field
                      as={StyledTextField}
                      name="password"
                      type="password"
                      placeholder="Password"
                      fullWidth
                      margin="normal"
                      error={touched.password && errors.password}
                      helperText={touched.password && errors.password}
                    />
                    <Link href="#" variant="body2" sx={{ display: 'block', textAlign: 'right', mb: 2, color: '#1976d2' }}>
                      Forgot Password
                    </Link>
                    <StyledButton
                      type="submit"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Sign in'}
                    </StyledButton>
                  </Form>
                )}
              </Formik>
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="body2">
                  Don't have an account?{' '}
                  <Link component={RouterLink} to="/signup" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Sign up
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
