import React, { useState, useEffect } from "react";
import { ChevronDown } from "../components/home/ChevronDown";
import api from '../utils/axiosInterceptor';
import { Box, Button, MenuItem, Select, Typography, Container, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';
import CarCard from "../components/home/CarCard";
import { Link } from "react-router-dom";
import SpecialOffers from "../components/home/SpecialOfferCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import tokenManager from "../utils/tokenManager";
import LoadingAnimation from "../components/LoadingAnimation";
import { Contour1 } from "../components/carListing/Contour1";


const FilterSection = styled(Box)(({ theme }) => ({
  //   backgroundColor: '#ffffff',
  //   borderRadius: '50px',
  padding: theme.spacing(4, 6),
  width: '95%',
  // maxWidth: '1200px', // Adjust this value as needed
  margin: '0 auto',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#011c2f',
  color: '#ffffff',
  borderRadius: '10px',
  '& .MuiSelect-select': {
    padding: '15px 20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: '#ffffff',
    fontSize: '1.5rem', // Adjust icon size
    right: '10px', // Position icon
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#efb600',
  color: '#ffffff',
  borderRadius: '12px',
  padding: '15px 40px',
  fontSize: '18px',
  fontWeight: 700,
  '&:hover': {
    backgroundColor: '#d9a400',
  },
}));







const WelcomeSection = styled(Box)(({ theme }) => ({
  // backgroundImage: "url('/home/frame-1000005263.png')",
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  padding: theme.spacing(10),
  color: theme.palette.common.white,
  textAlign: 'center',
}));








const BrandSection = styled(Box)(({ theme }) => ({
  padding: '50px 100px',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    padding: '30px 50px',
  },
}));

const BrandItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  margin: '10px',
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  borderRadius: '50%',
  height: '80px',
  width: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}));

const BrandImage = styled('img')({
  maxWidth: '60%',
  maxHeight: '60%',
  objectFit: 'contain',
});

const BrandName = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontSize: '24px',
  fontWeight: 400,
  letterSpacing: '-0.48px',
  textAlign: 'center',
}));





const ExploreMoreSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: '#f5f5f5',
}));

const ExploreCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ExploreImage = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
});

const ExploreContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#011c2f',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#03294a',
  },
}));

const CarGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  
  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CarContainer = styled(Link)`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  aspect-ratio: 16 / 9;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }
`;



const CarTitle = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 20px;
  color: white;
  font-family: "Inter-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  transform: translateY(0);
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    font-size: 28px;
  }

  ${CarContainer}:hover & {
    padding-bottom: 40px;
  }
`;

// Add these new components for additional features
const PriceTag = styled(Box)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.95);
  padding: 8px 14px;
  border-radius: 50px;
  color: #2563eb;
  font-weight: 600;
  font-size: 18px;
  z-index: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;


const theme = {
  primary: "#011c2f",
  secondary: "#149b09",
  accent: "#EFB600",
  background: "#f5f5f5",
  text: "#454a53",
  white: "#ffffff",
};
const CarImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "transform 0.6s ease",
  
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const Frame29 = styled(Box)(({ isApproved }) => ({
  alignItems: "center",
  backgroundColor: isApproved ? theme.secondary : theme.accent,
  color: "white",
  padding: "6px 12px",
  borderRadius: "50px",
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "uppercase",
  justifyContent: "center",
  position: "absolute",
  top: "20px",
  left: "20px",
  color: theme.white,
  fontFamily: "'Poppins', sans-serif",
  textAlign: "center",

}));

const Favorites = () => {
  const [cars, setCars] = useState([]);

  const [loading, setLoading] = useState(true);




  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const carsResponse = await
        api.get('/favorite', { params: { "userId": tokenManager.getUserId() } });


      setCars(carsResponse.data.results);


    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };







  return (
    <Box sx={{ padding: 0, margin: 0, backgroundColor: 'background.default', width: "100%" }}>
      {/* <Header /> */}

      <Container maxWidth={false} sx={{ padding: 0, margin: 0, width: "100%" }}>
        {/* <SpecialOffers cars={cars} loading={loading} isHome={true}/> */}
        {loading ? (
          <LoadingAnimation />
        ) : (
          <CarGrid sx={{margin:"20px"}}>
            {cars.map((car) => (
              <CarContainer key={car?.itemId?.id} to={`/car-listing/${car?.itemId?.id}`}>
                <CarImage
                  src={car?.itemId?.thumbnail || "/api/placeholder/400/360"}
                  alt={car?.itemId?.model || 'Car'}
                />

                <Frame29 isApproved={car?.itemId?.isApproved}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.white,
                      fontFamily: "'Poppins', sans-serif",
                      textAlign: "center",
                      fontSize: "8px",
                      fontWeight: "500"
                    }}
                  >
                    {car?.itemId?.isApproved ? "Inspection Passed" : "Pending Inspection"}
                  </Typography>
                  <Contour1 className="contour-instance" />
                </Frame29>
                <PriceTag>ETB {car?.itemId?.price?.toLocaleString() || '00,000'}

                </PriceTag>
                <CarTitle>
                  {car?.itemId?.category || `${car?.itemId?.brand?.name} ${car?.itemId?.model}`}
                </CarTitle>

              </CarContainer>
            ))}
          </CarGrid>)}
      </Container>

      {/* <Footer /> */}
    </Box>
  );
};


export default Favorites;

